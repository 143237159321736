@tailwind base;
@tailwind components;
@tailwind utilities;

@import url(./assets/fonts/Inter/inter.face.scss);

#vexa-content-div {
    position: fixed;
    width: 400px;
    height: 100vh;
    top: 0;
    overflow: hidden;
    right: 0;
    
    @apply bg-slate-950;

    * {
        font-family: 'Inter', sans-serif !important;

        ::-webkit-scrollbar {
            display: none;
        }

        ::-webkit-scrollbar {
            width: 8px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
            background: #0C111D;
            border-radius: 4px;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: #272829;
            border-radius: 4px;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: #333741;
        }
    }
}