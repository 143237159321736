.AssistantList.AssistantList2 {

  .c-select-container {
    width: calc(100% - 85px);
  }

  .new-btn {
    float: right;
    width: 80px;
  }

  .CustomSelect {
    @apply h-full;
    // max-width: 275px !important;

    .custom--dropdown-container {
      @apply rounded-md border-0 h-full disabled:bg-[#1F242F] bg-[#161B26] text-center font-semibold text-base;

      .dropdown-menu {
        background-color: #1F242F;
        border: none;
        min-height: 0;
        padding: 0;
        @apply flex flex-col gap-2;
      }

      .dropdown-input {
        @apply py-2 px-3;
      }
    }
  }

  .AssistantInput {
    // width: calc(100% - 2rem);
  }
}