@import url(../../../root.scss);

.RequestAccess {
    * {
        font-family: 'Inter';
    }
  .VexaLogo {
    h2 {
        @apply text-[#F5F5F6] #{!important};
    }
  }
}
