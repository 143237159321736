.Dashboard {
    * {
        font-family: 'Inter', sans-serif !important;

        ::-webkit-scrollbar {
            // display: none;
        }

        ::-webkit-scrollbar {
            width: 8px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
            background: #0C111D;
            border-radius: 4px;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: #272829;
            border-radius: 4px;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: #333741;
        }
    }

    .ViewColumns {
        >div:not(:last-of-type) {
            border-right-width: 1px;
            --tw-border-opacity: 1;
            border-right-color: rgb(31 36 47 / var(--tw-border-opacity));
            // @apply border-r border-r-[#1F242F];
        }
    }
}